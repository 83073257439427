import Vue from "vue";
import * as Api from "../../axios-generated/backoffice";
// @ts-ignore
import axios from "@axios";

const FLOWS_API = new Api.FlowsApi(
  undefined,
  Vue.prototype.$config.VUE_APP_BACKOFFICE_API_BASE_URL,
  axios
);

const SUBSCRIPTIONS_API = new Api.SubscriptionsApi(
  undefined,
  Vue.prototype.$config.VUE_APP_BACKOFFICE_API_BASE_URL,
  axios
);

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    findFlows(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        FLOWS_API.findFlows(
          queryParams.sortBy,
          queryParams.perPage,
          queryParams.page,
          queryParams.sortDesc
        )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateFlowVisibility(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        FLOWS_API.updateFlowVisibility(queryParams.flowID, queryParams.statusIn)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    findFlowsBySubscriptionID(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        SUBSCRIPTIONS_API.findFlowsBySubscriptionID(
          queryParams.subscriptionID,
          queryParams.sortBy,
          queryParams.perPage,
          queryParams.page,
          queryParams.sortDesc
        )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
