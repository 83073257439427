// @ts-ignore
import store from "@/store";
import { ref } from "@vue/composition-api";

// Notification
import { useToast } from "vue-toastification/composition";
// @ts-ignore
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useMetrics() {
  const toast = useToast();

  const metrics = ref([]);

  const params = ref({
    nbDays: 1,
    subscriptionId: "",
  });

  const paramsOptions = ref({
    nbDaysOptions: [
      {
        label: "Dernière semaine",
        value: 7,
      },
      {
        label: "Dernier jour",
        value: 1,
      },
    ],
  });

  const fetchSituationMetrics = () => {
    return new Promise((resolve, reject) => {
      store
        .dispatch("app-metrics/fetchSituationMetrics", params.value)
        .then((response) => {
          metrics.value = response.data;
          resolve(response);
        })
        .catch((err) => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Serveur Emplacement indisponible",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          reject(err);
        });
    });
  };

  const fetchRealestateMetrics = () => {
    return new Promise((resolve, reject) => {
      store
        .dispatch("app-metrics/fetchRealestateMetrics", params.value)
        .then((response) => {
          metrics.value = response.data;
          resolve(response);
        })
        .catch((err) => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Serveur Realestate indisponible",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          reject(err);
        });
    });
  };

  const fetchEstimateMetrics = () => {
    return new Promise((resolve, reject) => {
      store
        .dispatch("app-metrics/fetchEstimateMetrics", params.value)
        .then((response) => {
          metrics.value = response.data;
          resolve(response);
        })
        .catch((err) => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Serveur Estimate indisponible",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          reject(err);
        });
    });
  };

  return {
    fetchSituationMetrics,
    fetchRealestateMetrics,
    fetchEstimateMetrics,

    metrics,
    params,
    paramsOptions,
  };
}
