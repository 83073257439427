<template>
  <div>
    <b-row class="match-height mt-1">
      <b-col cols="2">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          v-b-modal.modal-create-apikey
          class="ml-2 mb-2"
        >
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span>Nouvelle clef d'API</span>
        </b-button>
      </b-col>
    </b-row>

    <modal-create-apikey
      :subscription-id="subscriptionID"
      @update-apikey-list="updateApikeyList"
    />

    <b-tabs>
      <b-tab active>
        <template #title>
          <feather-icon icon="KeyIcon" />
          <span>Accés</span>
        </template>

        <b-row>
          <b-col cols="12" xl="8" lg="8" md="7">
            <apikey-list-array-view
              ref="apikeyList"
              :subscription-id="subscriptionID"
              @apikey-selected="apikeySelected"
            />
          </b-col>
          <b-col cols="12" xl="4" lg="8" md="7" v-if="selectedApikey">
            <apikey-card-edit :selected-apikey="selectedApikey" />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab v-if="productCode === 'realestate'">
        <template #title>
          <feather-icon icon="WindIcon" />
          <span>Flux</span>
        </template>

        <flows-filter :subscription-id="subscriptionID"></flows-filter>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="ActivityIcon" />
          <span>Utilisation</span>
        </template>

        <api-metrics-card
          :subscription-id="subscriptionID"
          :product-code="productCode"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { PageBoxed } from "@/views/utils/PageBoxed.js";
import { useRouter } from "@core/utils/utils";
import Ripple from "vue-ripple-directive";
import { VBModal, BRow, BCol, BButton, BTabs, BTab } from "bootstrap-vue";

// Vue Components
import ApikeyListArrayView from "@/views/apikeys/apikey-list/ApikeyListArrayView.vue";
import FlowsFilter from "@/views/flows-filter/filter-draggable/FlowsFilter.vue";
import ModalCreateApikey from "@/views/apikeys/apikey-modal-create/ModalCreateApikey.vue";
import ApikeyCardEdit from "@/views/apikeys/apikey-card-edit/ApikeyCardEdit.vue";
import ApiMetricsCard from "@/views/api-metrics/ApiMetricsCard.vue";

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BTabs,
    BTab,

    // Local Components
    ApikeyListArrayView,
    FlowsFilter,
    ModalCreateApikey,
    ApikeyCardEdit,
    ApiMetricsCard,
  },
  mixins: [PageBoxed],
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      selectedApikey: null,
    };
  },
  methods: {
    apikeySelected(apikey) {
      this.selectedApikey = apikey;
    },
    updateApikeyList() {
      this.$refs.apikeyList.updateApikeys();
    },
  },
  setup() {
    // Pull subscriptionID and productID from router
    const { route } = useRouter();
    const subscriptionID = route.value.query.subscriptionID;
    const productCode = route.value.query.productCode;

    return {
      subscriptionID,
      productCode,
    };
  },
};
</script>

<style></style>
