import { ref, watch } from "@vue/composition-api";
// @ts-ignore
import store from "@/store";
// Notification
import { useToast } from "vue-toastification/composition";
// @ts-ignore
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useFlowsFilterList() {
  // Use toast
  const toast = useToast();

  const listFlow = ref([]);
  const listFlowEnabled = ref([]);

  // Table Handlers
  const subscriptionId = ref("");
  const perPage = ref(50);
  const total = ref(0);
  const currentPage = ref(1);
  const sortBy = ref("name");
  const isSortDirDesc = ref(false);

  watch([currentPage, perPage], () => {
    findFlows();
  });

  const findFlows = () => {
    store
      .dispatch("app-flows-filter/findFlowsBySubscriptionID", {
        subscriptionID: subscriptionId.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then((response) => {
        listFlowEnabled.value = response.data.flows;
        store
          .dispatch("app-flows-filter/findFlows", {
            perPage: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
          })
          .then((response) => {
            total.value = response.data.count - listFlowEnabled.value.length;
            listFlow.value = response.data.flows.filter((el) => {
              return !listFlowEnabled.value.some((f) => {
                return f.ID === el.ID;
              });
            });
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error fetching flows' list",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching flows enabled' list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const updateFlowVisibility = (flowID, visibility) => {
    const queryParams = {
      flowID,
      statusIn: {
        subscription_ID: subscriptionId.value,
        enable: visibility,
      },
    };
    store
      .dispatch("app-flows-filter/updateFlowVisibility", queryParams)
      .then(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Modification validée",
            icon: "EditIcon",
            variant: "success",
          },
        });
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Erreur lors de l'enregistrement",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  return {
    findFlows,
    listFlow,
    listFlowEnabled,
    updateFlowVisibility,
    perPage,
    currentPage,
    total,

    subscriptionId,
  };
}
