import store from "@/store";

import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useApikeysCreate() {
  const toast = useToast();

  const createApikey = (newApikey) => {
    return new Promise((resolve, reject) => {
      store
        .dispatch("app-apikey/createApiKey", { newApikey })
        .then((response) => {
          toast(
            {
              component: ToastificationContent,
              props: {
                title: "Création validée",
                icon: "SuccessIcon",
                variant: "success",
              },
            },
            { position: "top-center" }
          );
          resolve(response);
        })
        .catch((error) => {
          toast(
            {
              component: ToastificationContent,
              props: {
                title: "Erreur durant la création",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            },
            { position: "top-center" }
          );
          reject(error);
        });
    });
  };

  return {
    createApikey,
  };
}
