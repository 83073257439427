<template>
  <div>
    <!-- modal -->
    <b-modal
      id="modal-create-apikey"
      ref="create-apikey-modal"
      title="Création d'une clef d'API"
      centered
      ok-title="Valider"
      cancel-title="Retour"
      cancel-variant="outline-secondary"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          :state="nameState"
          label="Nom"
          label-for="name-input"
          invalid-feedback="Le nom est nécessaire"
        >
          <b-form-input
            id="name-input"
            v-model="newApikey.name"
            placeholder="Nom de la clef"
            :state="nameState"
            required
          />
        </b-form-group>
        <b-form-group label="Date de validité" label-for="validity-date-input">
          <b-input-group class="mb-1">
            <b-form-input
              id="example-input"
              v-model="newApikey.validity_date"
              type="text"
              placeholder="YYYY-MM-DD"
              autocomplete="off"
              show-decade-nav
            />
            <b-input-group-append>
              <b-form-datepicker
                v-model="newApikey.validity_date"
                show-decade-nav
                button-only
                button-variant="outline-primary"
                right
                size="sm"
                locale="fr-FR"
                aria-controls="example-input"
                @context="onContext"
              />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-form-group label="Domaines actifs" label-for="white_list-input">
          <b-form-tags v-model="whiteListTags" no-outer-focus>
            <template
              #default="{ tags, inputAttrs, inputHandlers, addTag, removeTag }"
            >
              <b-input-group aria-controls="my-custom-tags-list">
                <input
                  v-bind="inputAttrs"
                  placeholder="https://client-domain.com"
                  class="form-control"
                  v-on="inputHandlers"
                />
                <b-input-group-append>
                  <b-button variant="primary" @click="addTag()">
                    Ajouter
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <ul
                id="my-custom-tags-list"
                class="list-unstyled d-inline-flex flex-wrap mb-0"
                aria-live="polite"
                aria-atomic="false"
                aria-relevant="additions removals"
              >
                <!-- Always use the tag value as the :key, not the index! -->
                <!-- Otherwise screen readers will not read the tag
                  additions and removals correctly -->
                <b-card
                  v-for="tag in tags"
                  :id="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                  :key="tag"
                  tag="li"
                  class="shadow-none border mt-1 mr-1 mb-0"
                  body-class="py-1 pr-2 text-nowrap"
                >
                  <strong>{{ tag }}</strong>
                  <feather-icon
                    icon="DeleteIcon"
                    style="color: red; cursor: pointer"
                    class="ml-50"
                    @click="removeTag(tag)"
                  />
                </b-card>
              </ul>
            </template>
          </b-form-tags>
        </b-form-group>
      </form>
    </b-modal>

    <b-modal
      ref="modal-success-create-apikey"
      title="Clef d'API générée 🚀"
      centered
      ok-only
      ok-title="Terminer"
      @hidden="closeModal"
    >
      <b-row>
        <b-col sm="12">
          <b-form-group label="Clef" label-for="key" class="mb-2">
            <b-form-input id="key" v-model="generatedKey" readonly />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
  BFormTags,
  BButton,
  BCard,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import useApikeysCreate from "./useApikeysCreate";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    BFormTags,
    BButton,
    BCard,
    BModal,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      newApikey: {},
      generatedKey: null,
      nameState: null,
    };
  },
  computed: {
    whiteListTags: {
      get() {
        if (
          !this.newApikey.white_list ||
          this.newApikey.white_list.length === 0
        )
          return [];
        return JSON.parse(this.newApikey.white_list);
      },
      set(value) {
        this.newApikey.white_list = JSON.stringify(value);
      },
    },
  },
  setup() {
    const { createApikey } = useApikeysCreate();

    return {
      createApikey,
    };
  },
  props: {
    subscriptionId: {
      type: String,
      required: true,
    },
  },
  methods: {
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD;
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    closeModal() {
      this.$emit("update-apikey-list");
    },
    resetModal() {
      this.nameState = null;
      this.newApikey = {};
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }

      if (this.newApikey.validity_date) {
        this.newApikey.validity_date = new Date(
          this.newApikey.validity_date
        ).toISOString();
      }
      this.newApikey.subscription_ID = this.subscriptionId;

      this.createApikey(this.newApikey).then((response) => {
        this.generatedKey = response.data.token;
        this.$refs["modal-success-create-apikey"].show();
        this.$emit("update-apikey-list");
        this.newApikey = { white_list: [] };
        // Hide the modal manually
        this.$nextTick(() => {
          this.$refs["create-apikey-modal"].toggle("#toggle-btn");
        });
      });
    },
  },
};
</script>
