import Vue from "vue";
import * as ApiSituation from "../../axios-generated/situation";
import * as ApiRealestate from "../../axios-generated/realestate";
import * as ApiEstimate from "../../axios-generated/estimate";
// @ts-ignore
import axios from "@axios";

const SITUATION_METRICS_API = new ApiSituation.MetricsApi(
  undefined,
  Vue.prototype.$config.VUE_APP_SITUATION_API_BASE_URL,
  axios
);

const REALESTATE_METRICS_API = new ApiRealestate.MetricsApi(
  undefined,
  Vue.prototype.$config.VUE_APP_REALESTATE_API_BASE_URL,
  axios
);

const ESTIMATE_METRICS_API = new ApiEstimate.MetricsApi(
  undefined,
  Vue.prototype.$config.VUE_APP_ESTIMATE_API_BASE_URL,
  axios
);

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSituationMetrics(ctx, { nbDays, subscriptionId }) {
      return new Promise((resolve, reject) => {
        SITUATION_METRICS_API.getMetrics(subscriptionId, nbDays)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchRealestateMetrics(ctx, { nbDays, subscriptionId }) {
      return new Promise((resolve, reject) => {
        REALESTATE_METRICS_API.getMetrics(subscriptionId, nbDays)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchEstimateMetrics(ctx, { nbDays, subscriptionId }) {
      return new Promise((resolve, reject) => {
        ESTIMATE_METRICS_API.getMetrics(subscriptionId, nbDays)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
