<template>
  <e-charts
    ref="line"
    autoresize
    :options="option"
    theme="theme-color"
    auto-resize
  />
</template>

<script>
import ECharts from "vue-echarts";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/chart/line";
import theme from "./theme.json";

ECharts.registerTheme("theme-color", theme);

export default {
  components: {
    ECharts,
  },
  props: {
    optionData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      option: {
        title: {
          text: "Stacked area chart",
        },
        tooltip: {
          trigger: "axis",
          formatter: this.optionData.formatter,
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        legend: {
          left: 0,
          textStyle: {
            fontWeight: "bold",
          },
        },
        grid: {
          left: "52px",
          right: "30px",
          bottom: "30px",
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: this.optionData.xAxisData,
          },
        ],
        yAxis: [
          {
            type: "value",
            splitLine: {
              lineStyle: {
                type: "dashed",
                color: "#B8B8B8",
              },
            },
            max(value) {
              return value.max;
            },
            min(value) {
              return value.min;
            },
          },
        ],
        series: this.optionData.series,
      },
    };
  },
};
</script>
