var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"mx-3 mt-2"},[_c('b-col',{staticClass:"mb-1",attrs:{"md":"12"}},[_c('b-alert',{attrs:{"variant":"success","show":true}},[_c('h4',{staticClass:"alert-heading"},[_vm._v(" Vous pouvez faire glisser et déposer des éléments pour activer ou désactiver un flux d'annonces. ")])])],1),_c('b-col',{attrs:{"md":"6"}},[_c('h4',{staticClass:"text-primary font-weight-bold mb-2"},[_vm._v("Flux disponibles")]),_c('draggable',{staticClass:"list-group list-group-flush cursor-move flow-height",attrs:{"list":_vm.listFlow,"tag":"ul","group":"people"}},_vm._l((_vm.listFlow),function(listItem,index){return _c('b-list-group-item',{key:index,attrs:{"tag":"li"}},[_c('div',{staticClass:"d-flex"},[_c('b-avatar',{attrs:{"size":"20","src":"" + _vm.publicPath + "images/flux/" + "" + (listItem.logo),"text":listItem.name.slice(0, 2)}}),_c('div',{staticClass:"ml-25"},[_c('b-card-text',{staticClass:"mb-0 font-weight-bold"},[_vm._v(" "+_vm._s(listItem.name)+" "),_c('b-badge',{attrs:{"variant":listItem.status === 'CREATED' ||
                    listItem.status === 'ENABLED'
                      ? 'light-primary'
                      : 'light-warning'}},[_vm._v(" "+_vm._s(listItem.status)+" ")]),_c('b-badge',{attrs:{"variant":listItem.type_offers === 'NEUF'
                      ? 'light-info'
                      : 'light-secondary'}},[_vm._v(" "+_vm._s(listItem.type_offers)+" ")])],1)],1)],1)])}),1)],1),_c('b-col',{staticClass:"mt-sm-2 mt-md-0",attrs:{"md":"6"}},[_c('h4',{staticClass:"text-primary font-weight-bold mb-2"},[_vm._v("Flux activés")]),_c('draggable',{staticClass:"list-group list-group-flush cursor-move flow-height",attrs:{"list":_vm.listFlowEnabled,"tag":"ul","group":"people"},on:{"add":_vm.onAdd,"remove":_vm.onRemove}},_vm._l((_vm.listFlowEnabled),function(listItem,index){return _c('b-list-group-item',{key:index,attrs:{"tag":"li"}},[_c('div',{staticClass:"d-flex"},[_c('b-avatar',{attrs:{"size":"20","src":"" + _vm.publicPath + "images/flux/" + "" + (listItem.logo),"text":listItem.name.slice(0, 2)}}),_c('div',{staticClass:"ml-25"},[_c('b-card-text',{staticClass:"mb-0 font-weight-bold"},[_vm._v(" "+_vm._s(listItem.name)+" "),_c('b-badge',{attrs:{"variant":listItem.status === 'CREATED' ||
                    listItem.status === 'ENABLED'
                      ? 'light-primary'
                      : 'light-warning'}},[_vm._v(" "+_vm._s(listItem.status)+" ")]),_c('b-badge',{attrs:{"variant":listItem.type_offers === 'NEUF'
                      ? 'light-info'
                      : 'light-secondary'}},[_vm._v(" "+_vm._s(listItem.type_offers)+" ")])],1)],1)],1)])}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }