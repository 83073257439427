<template>
  <div>
    <b-row class="mx-3 mt-2">
      <b-col md="12" class="mb-1">
        <b-alert variant="success" :show="true">
          <h4 class="alert-heading">
            Vous pouvez faire glisser et déposer des éléments pour activer ou
            désactiver un flux d'annonces.
          </h4>
        </b-alert>
      </b-col>

      <b-col md="6">
        <h4 class="text-primary font-weight-bold mb-2">Flux disponibles</h4>

        <!-- draggable -->
        <draggable
          :list="listFlow"
          tag="ul"
          group="people"
          class="list-group list-group-flush cursor-move flow-height"
        >
          <b-list-group-item
            v-for="(listItem, index) in listFlow"
            :key="index"
            tag="li"
          >
            <div class="d-flex">
              <b-avatar
                size="20"
                :src="`${publicPath}` + `images/flux/` + `${listItem.logo}`"
                :text="listItem.name.slice(0, 2)"
              />
              <div class="ml-25">
                <b-card-text class="mb-0 font-weight-bold">
                  {{ listItem.name }}
                  <b-badge
                    :variant="
                      listItem.status === 'CREATED' ||
                      listItem.status === 'ENABLED'
                        ? 'light-primary'
                        : 'light-warning'
                    "
                  >
                    {{ listItem.status }}
                  </b-badge>
                  <b-badge
                    :variant="
                      listItem.type_offers === 'NEUF'
                        ? 'light-info'
                        : 'light-secondary'
                    "
                  >
                    {{ listItem.type_offers }}
                  </b-badge>
                </b-card-text>
              </div>
            </div>
          </b-list-group-item>
        </draggable>
      </b-col>

      <b-col md="6" class="mt-sm-2 mt-md-0">
        <h4 class="text-primary font-weight-bold mb-2">Flux activés</h4>

        <!-- draggable -->
        <draggable
          :list="listFlowEnabled"
          tag="ul"
          group="people"
          class="list-group list-group-flush cursor-move flow-height"
          @add="onAdd"
          @remove="onRemove"
        >
          <b-list-group-item
            v-for="(listItem, index) in listFlowEnabled"
            :key="index"
            tag="li"
          >
            <div class="d-flex">
              <b-avatar
                size="20"
                :src="`${publicPath}` + `images/flux/` + `${listItem.logo}`"
                :text="listItem.name.slice(0, 2)"
              />
              <div class="ml-25">
                <b-card-text class="mb-0 font-weight-bold">
                  {{ listItem.name }}
                  <b-badge
                    :variant="
                      listItem.status === 'CREATED' ||
                      listItem.status === 'ENABLED'
                        ? 'light-primary'
                        : 'light-warning'
                    "
                  >
                    {{ listItem.status }}
                  </b-badge>
                  <b-badge
                    :variant="
                      listItem.type_offers === 'NEUF'
                        ? 'light-info'
                        : 'light-secondary'
                    "
                  >
                    {{ listItem.type_offers }}
                  </b-badge>
                </b-card-text>
              </div>
            </div>
          </b-list-group-item>
        </draggable>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BAlert,
  BListGroupItem,
  BAvatar,
  BRow,
  BCol,
  BCardText,
  BBadge,
} from "bootstrap-vue";
import draggable from "vuedraggable";
import { onUnmounted } from "@vue/composition-api";
import store from "@/store";
import useFlowsFilterList from "./useFlowsFilterList";

import flowsFilterStoreModule from "../flowsFilterStoreModule";

export default {
  components: {
    BAlert,
    BAvatar,
    BListGroupItem,
    BRow,
    BCol,
    BCardText,
    BBadge,
    draggable,
  },
  props: {
    subscriptionId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const FLOWS_FILTER_APP_STORE_MODULE_NAME = "app-flows-filter";

    // Register module
    if (!store.hasModule(FLOWS_FILTER_APP_STORE_MODULE_NAME))
      store.registerModule(
        FLOWS_FILTER_APP_STORE_MODULE_NAME,
        flowsFilterStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FLOWS_FILTER_APP_STORE_MODULE_NAME))
        store.unregisterModule(FLOWS_FILTER_APP_STORE_MODULE_NAME);
    });

    const {
      findFlows,
      listFlow,
      listFlowEnabled,
      updateFlowVisibility,
      perPage,
      currentPage,
      total,

      subscriptionId,
    } = useFlowsFilterList();

    subscriptionId.value = props.subscriptionId;
    findFlows();

    return {
      findFlows,
      listFlow,
      listFlowEnabled,
      updateFlowVisibility,
      perPage,
      currentPage,
      total,
    };
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  methods: {
    onAdd(e) {
      this.updateFlowVisibility(e.item._underlying_vm_.ID, true);
    },
    onRemove(e) {
      this.updateFlowVisibility(e.item._underlying_vm_.ID, false);
    },
  },
};
</script>

<style scoped>
.flow-height {
  background: white;
  height: 38rem;
  overflow: auto;
}
</style>
