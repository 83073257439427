<template>
  <b-card class="mx-3">
    <b-row align-h="between">
      <b-col>
        <h4>Utilisation de l'API</h4>
      </b-col>
      <b-col class="text-right">
        <b-form-radio-group
          id="interval-select"
          v-model="params.nbDays"
          :options="paramsOptions.nbDaysOptions"
          value-field="value"
          text-field="label"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="12" class="mt-1" v-if="metrics.length > 0">
        <app-echart-stacked-area :option-data="option" class="trend-chart" />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import store from "@/store";
import { BCard, BRow, BCol, BFormRadioGroup } from "bootstrap-vue";
import { onUnmounted } from "@vue/composition-api";

import metricsStoreModule from "./metricsStoreModule";
import useMetrics from "./useMetrics";

// Vue components
import AppEchartStackedArea from "@core/components/charts/echart/AppEchartStackedArea.vue";

// Custom formater
import { formatReverseDate } from "@core/utils/filter";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormRadioGroup,

    AppEchartStackedArea,
  },
  props: {
    subscriptionId: {
      type: String,
      required: true,
    },
    productCode: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const METRICS_APP_STORE_MODULE_NAME = "app-metrics";

    // Register module
    if (!store.hasModule(METRICS_APP_STORE_MODULE_NAME))
      store.registerModule(METRICS_APP_STORE_MODULE_NAME, metricsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(METRICS_APP_STORE_MODULE_NAME))
        store.unregisterModule(METRICS_APP_STORE_MODULE_NAME);
    });

    const {
      fetchSituationMetrics,
      fetchRealestateMetrics,
      fetchEstimateMetrics,

      metrics,
      params,
      paramsOptions,
    } = useMetrics();

    params.value.subscriptionId = props.subscriptionId;

    if (props.productCode === "realestate") fetchRealestateMetrics();
    if (props.productCode === "situation") fetchSituationMetrics();
    if (props.productCode === "estimate") fetchEstimateMetrics();

    return {
      fetchSituationMetrics,
      fetchRealestateMetrics,
      fetchEstimateMetrics,

      metrics,
      params,
      paramsOptions,
    };
  },
  watch: {
    "params.nbDays"() {
      this.metrics = [];

      if (this.productCode === "realestate") this.fetchRealestateMetrics();
      if (this.productCode === "situation") this.fetchSituationMetrics();
      if (this.productCode === "estimate") this.fetchEstimateMetrics();
    },
  },
  computed: {
    option: {
      get() {
        return {
          xAxisData: this.metrics.map(
            (elem) =>
              formatReverseDate(elem.time) + " " + elem.time.slice(11, 16)
          ),
          series: [
            {
              name: "Http request",
              type: "line",
              showSymbol: true,
              lineStyle: {
                width: 3,
              },
              data: this.metrics.map((elem) => elem.count),
            },
          ],
        };
      },
      set(oldval, val) {
        if (oldval != val) {
          this.option = val;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.trend-chart {
  width: 100%;
  height: 26rem;
}
</style>
