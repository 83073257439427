import { ref, watch, computed } from "@vue/composition-api";
// @ts-ignore
import store from "@/store";
// Notification
import { useToast } from "vue-toastification/composition";
// @ts-ignore
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useApikeysList() {
  // Use toast
  const toast = useToast();

  const refApikeyListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "nom", sortable: true },
    { key: "date_création", sortable: true },
    { key: "date_expiration", sortable: true },
    { key: "statut", sortable: true },
  ];
  const subscriptionId = ref("");
  const perPage = ref(9);
  const totalApikeys = ref(0);
  const currentPage = ref(1);
  const sortBy = ref("uuid");
  const isSortDirDesc = ref(true);

  const dataMeta = computed(() => {
    const localItemsCount = refApikeyListTable.value
      ? refApikeyListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalApikeys.value,
    };
  });

  const refetchData = () => {
    refApikeyListTable.value.refresh();
  };

  const filterSortBy = (sortByParam) => {
    switch (sortByParam) {
      case "nom":
        return "name";
      case "date_création":
        return "created_at";
      case "date_expiration":
        return "validity_date";
      case "statut":
        return "enabled";
      default:
        return "uuid";
    }
  };

  watch([currentPage, perPage], () => {
    refetchData();
  });

  const fetchApikeys = (ctx, callback) => {
    const sortByFiltered = filterSortBy(sortBy.value);
    store
      .dispatch("products/findAPIKeysBySubscriptionID", {
        subscriptionId: subscriptionId.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortByFiltered,
        sortDesc: isSortDirDesc.value,
      })
      .then((response) => {
        const apikeys = response.data.apikeys;
        totalApikeys.value = response.data.count;
        callback(apikeys);
      })
      .catch((error) => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching apikeys' list",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: error.message,
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveStatusVariant = (enabled) => {
    if (enabled) return "success";
    return "warning";
  };

  return {
    fetchApikeys,
    tableColumns,
    perPage,
    currentPage,
    totalApikeys,
    dataMeta,
    sortBy,
    isSortDirDesc,
    refApikeyListTable,

    resolveStatusVariant,

    refetchData,
    subscriptionId,
  };
}
