import store from "@/store";

import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useApikeysEdit() {
  const toast = useToast();

  const editApikey = (editApikey) => {
    store
      .dispatch("app-apikey/editApiKey", { editApikey })
      .then(() => {
        toast(
          {
            component: ToastificationContent,
            props: {
              title: "Edition validée",
              icon: "EditIcon",
              variant: "success",
            },
          },
          { position: "top-center" }
        );
      })
      .catch(() => {
        toast(
          {
            component: ToastificationContent,
            props: {
              title: "Erreur durant l'édition",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          },
          { position: "top-center" }
        );
      });
  };

  return {
    editApikey,
  };
}
