<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <b-table
      ref="refApikeyListTable"
      :items="fetchApikeys"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
      selectable
      select-mode="single"
      @row-selected="onRowSelected"
    >
      <!-- Column: Client -->
      <template #cell(nom)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar rounded size="42" :variant="'light-primary'">
              <feather-icon size="18" :icon="'KeyIcon'" />
            </b-avatar>
          </template>
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.name }}
          </span>
        </b-media>
      </template>

      <!-- Column: Entreprise -->
      <template #cell(date_création)="data">
        <span class="text-nowrap">
          {{ data.item.created_at ? data.item.created_at.slice(0, 10) : null }}
        </span>
      </template>

      <!-- Column: Emploi -->
      <template #cell(date_expiration)="data">
        <span class="text-nowrap">
          {{
            !data.item.validity_date
              ? "Infini"
              : data.item.validity_date.slice(0, 10)
          }}
        </span>
      </template>

      <!-- Column: Status -->
      <template #cell(statut)="data">
        <b-badge
          pill
          :variant="`light-${resolveStatusVariant(data.item.enabled)}`"
        >
          {{ data.item.enabled ? "Activé" : "Désactivé" }}
        </b-badge>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalApikeys"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BMedia,
  BAvatar,
  BBadge,
  BPagination,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import { resolveAvatarLink } from "@core/utils/utils";
import { onUnmounted } from "@vue/composition-api";
import store from "@/store";
import useApikeysList from "./useApikeyList";

import apikeyStoreModule from "../apikeyStoreModule";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BMedia,
    BAvatar,
    BBadge,
    BPagination,
  },
  props: {
    subscriptionId: {
      type: String,
      required: true,
    },
  },
  methods: {
    onRowSelected(items) {
      this.$emit("apikey-selected", items[0]);
    },
    updateApikeys() {
      this.refetchData();
    },
  },
  setup(props) {
    const APIKEY_APP_STORE_MODULE_NAME = "app-apikey";

    // Register module
    if (!store.hasModule(APIKEY_APP_STORE_MODULE_NAME))
      store.registerModule(APIKEY_APP_STORE_MODULE_NAME, apikeyStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APIKEY_APP_STORE_MODULE_NAME))
        store.unregisterModule(APIKEY_APP_STORE_MODULE_NAME);
    });

    const {
      fetchApikeys,
      tableColumns,
      filterCompanyId,
      perPage,
      currentPage,
      totalApikeys,
      dataMeta,
      sortBy,
      isSortDirDesc,
      refApikeyListTable,

      refetchData,

      resolveStatusVariant,
      subscriptionId,
    } = useApikeysList();

    subscriptionId.value = props.subscriptionId;

    return {
      fetchApikeys,
      tableColumns,
      filterCompanyId,
      perPage,
      currentPage,
      totalApikeys,
      dataMeta,
      sortBy,
      isSortDirDesc,
      refApikeyListTable,

      refetchData,

      avatarText,
      resolveStatusVariant,
      resolveAvatarLink,
    };
  },
};
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}

.apikey-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
