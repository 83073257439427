<template>
  <b-card no-body class="card-apikey-edit">
    <b-card-header>
      <b-card-title>Clé d'API</b-card-title>
      <b-card-title>🔒</b-card-title>
    </b-card-header>
    <b-card-body>
      <!-- form -->
      <b-form ref="formEdit">
        <b-row>
          <b-col sm="9">
            <b-form-group label="Clef" label-for="key" class="mb-2">
              <b-form-input id="key" v-model="selectedApikey.token" readonly />
            </b-form-group>
          </b-col>
          <b-col sm="3" class="mt-2">
            <b-button
              @click="doCopy"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="primary"
            >
              Copier
            </b-button>
          </b-col>
          <b-col cols="12">
            <b-form-group
              :state="nameState"
              label="Nom"
              label-for="name-input"
              invalid-feedback="Le nom est nécessaire"
            >
              <b-form-input
                id="name-input"
                v-model="selectedApikey.name"
                placeholder="Nom de la clef"
                :state="nameState"
                required
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Domaines actifs" label-for="white_list-input">
              <b-form-tags v-model="whiteListTags" no-outer-focus>
                <template
                  #default="{
                    tags,
                    inputAttrs,
                    inputHandlers,
                    addTag,
                    removeTag,
                  }"
                >
                  <b-input-group aria-controls="my-custom-tags-list">
                    <input
                      v-bind="inputAttrs"
                      placeholder="https://client-domain.com"
                      class="form-control"
                      v-on="inputHandlers"
                    />
                    <b-input-group-append>
                      <b-button variant="primary" @click="addTag()">
                        Ajouter
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <ul
                    id="my-custom-tags-list"
                    class="list-unstyled d-inline-flex flex-wrap mb-0"
                    aria-live="polite"
                    aria-atomic="false"
                    aria-relevant="additions removals"
                  >
                    <!-- Always use the tag value as the :key, not the index! -->
                    <!-- Otherwise screen readers will not read the tag
                      additions and removals correctly -->
                    <b-card
                      v-for="tag in tags"
                      :id="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                      :key="tag"
                      tag="li"
                      class="shadow-none border mt-1 mr-1 mb-0"
                      body-class="py-1 pr-2 text-nowrap"
                    >
                      <strong>{{ tag }}</strong>
                      <feather-icon
                        icon="DeleteIcon"
                        style="color: red; cursor: pointer"
                        class="ml-50"
                        @click="removeTag(tag)"
                      />
                    </b-card>
                  </ul>
                </template>
              </b-form-tags>
            </b-form-group>
          </b-col>
          <b-col cols="5">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              block
              variant="primary"
              @click="handleSubmit"
            >
              <feather-icon icon="EditIcon" class="mr-50" />
              Editer
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <!--/ form -->
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  BInputGroup,
  BInputGroupAppend,
  BFormTags,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import useApikeysEdit from "./useApikeysEdit";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BFormTags,
  },
  directives: {
    Ripple,
  },
  props: {
    selectedApikey: {
      type: Object,
      required: true,
    },
  },
  computed: {
    whiteListTags: {
      get() {
        if (
          !this.selectedApikey.white_list ||
          this.selectedApikey.white_list.length === 0
        )
          return [];
        return JSON.parse(this.selectedApikey.white_list);
      },
      set(value) {
        this.selectedApikey.white_list = JSON.stringify(value);
      },
    },
  },
  data() {
    return {
      nameState: null,
    };
  },
  setup() {
    const { editApikey } = useApikeysEdit();
    return {
      editApikey,
    };
  },
  methods: {
    checkFormValidity() {
      const valid = this.$refs.formEdit.checkValidity();
      this.nameState = valid;
      return valid;
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      this.editApikey(this.selectedApikey);
    },
    // CLIPBOARD BUTTON
    doCopy() {
      this.$copyText(this.selectedApikey.token).then(
        () => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Cléf d'API copié",
                icon: "BellIcon",
              },
            },
            { position: "top-center" }
          );
        },
        () => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Impossible de copier la cléf",
                icon: "BellIcon",
              },
            },
            { position: "top-center" }
          );
        }
      );
    },
  },
};
</script>
