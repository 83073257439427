import Vue from "vue";
import * as Api from "../../axios-generated/backoffice/";
// @ts-ignore
import axios from "@axios";

const APIKEYS_API = new Api.ApiKeysApi(
  undefined,
  Vue.prototype.$config.VUE_APP_BACKOFFICE_API_BASE_URL,
  axios
);

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    createApiKey(ctx, { newApikey }) {
      return new Promise((resolve, reject) => {
        APIKEYS_API.createAPIKey(newApikey)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    editApiKey(ctx, { editApikey }) {
      return new Promise((resolve, reject) => {
        APIKEYS_API.updateAPIKey(editApikey.ID, editApikey)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
